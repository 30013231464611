import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiOrdersService } from 'src/app/services/Order/api-orders.service';
import { DataOrDer } from '../models/order';

@Component({
  selector: 'app-status-modal',
  templateUrl: './status-modal.component.html',
  styleUrls: ['./status-modal.component.scss']
})
export class StatusModalComponent implements OnInit {

  @Output() childEdited: EventEmitter<string> =   new EventEmitter();
  @Input() statusOrder :DataOrDer ;
  @ViewChild( "showModale") showModale;
  @ViewChild("closeButton") closeButton;
  allStatus  = [1 , 2 , 3 , 4]
  constructor(private http :ApiOrdersService) { }

  ngOnInit(): void {
  }

  errorMessage :string;
  getColor(status :number){
   
    switch(status){
            case 1 :
                return "#F3A521";
            case 2 :
                return "#001EED";
            case 3 :
                return "#6ED540";
            case 4 :
                return "#F14272";
            default:
                return "#F3A521";
        }
  }

  getStatus(status :number){
    switch(status){
      case 1 :
          return "قيد الانتظار";
      case 2 :
          return "تم التجهيز";
      case 3 :
          return "تم الاستلام";
      case 4 :
          return "تم الرفض";
      default:
          return "قيد الانتظار";
    }

  }

  ChangeStatus(body :NgForm ,id ){
    this.errorMessage="";
    this.http.changeStatus(body , id).subscribe(response=>{ 
          if(response.status == 1 ){
            this.closeButton.nativeElement.click();
            this.childEdited.emit('change Status Successfully');
          }else{
            this.errorMessage ='* '+ response.message;
          }
    })
}
}
