import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiOrdersService } from 'src/app/services/Order/api-orders.service';
import { DataOrDer, Product } from '../models/order';

@Component({
  selector: 'app-single-oreder-modal',
  templateUrl: './single-oreder-modal.component.html',
  styleUrls: ['./single-oreder-modal.component.scss']
})
export class SingleOrederModalComponent implements OnInit {

  orders :DataOrDer[];
  loading =true;
  @Output() childShow: EventEmitter<string> =   new EventEmitter();
  @Input() produst : Product[];
  @Input() order : DataOrDer;
  constructor(private http :ApiOrdersService, private router :Router) { 
    let token = localStorage.getItem('token');
    if(token == null){
      this.router.navigate(['/login']);
    }
   }
  ngOnInit(): void {
  
  }

}
