import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApisAuthService } from 'src/app/services/apis-auth.service';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.scss']
})
export class LeftsidebarComponent implements OnInit {

  constructor(private router :Router, private httpUser :ApisAuthService) { }

  userName = "";
  email = ""
  
  ngOnInit(): void {
     this.userName = localStorage.getItem('name')
    this.email = localStorage.getItem('email')
    
  }
  wishlistCartNotfication(page){
    let token = localStorage.getItem('token')
    
    if(token == null){
      this.router.navigate(["/logIn"]);
      return;
    }else{
      this.router.navigate([page]);
      return;
    }
  }

}
