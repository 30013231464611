<div id="wrapper">
  <app-leftsidebar *ngIf="router.url != '/login'"></app-leftsidebar>
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->

  <div class="content-page">
    <app-topbar *ngIf="router.url != '/login'"></app-topbar>
      <!-- Start Page content -->
      <router-outlet></router-outlet>
       <!-- content -->

  </div>
</div>