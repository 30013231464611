    <!-- Start Page content -->
    <div class="content">
        <div class="container-fluid">

            <div class="row">
                <div class="col-12">
                    <div class="card-box">
                        <div class="topHeader">
                            <h3>Images
                                <span class="buttonModale">
                                    <app-add-slider-model (childAdded)="onChildAdded($event)"></app-add-slider-model>
                                </span>
                            </h3>
                        </div>
                        <div class="table-rep-plugin">
                            
                            <div class="table-responsive" data-pattern="priority-columns">
                                <table id="tech-companies-1" class="table  table-striped">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>select</th>
                                        <th>Slider Image</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    
                                    <tbody  *ngIf='!loading'>
                                    <tr class="sliderImage" *ngFor='let item of sliders'>
                                        <th>{{item.id}}</th>
                                        <th><input type="checkbox" #checkboxes type="checkbox" name="chek" data-md-icheck ngModel (ngModelChange)="selectItem($event,item)"></th>
                                        <th><img src="{{item.slider_image}}"></th>
                                        
                                        
                                        <td>
                                            <ul class="StatusIcon">
                                                <li class="edit"><app-edit-slider-modal [slider]=item (childEdited)='onChildAdded($event)'></app-edit-slider-modal></li>
                                                <li class="delete" (click)='clickSweet(item.id)'><i class="fa fa-trash"></i></li>
                                            </ul>
                                        </td>
                                        
                                    </tr>

                                    </tbody>
                                </table>
                                <div class="responsiveLoading" *ngIf='loading'>
                                    <span><i class="fa fa-circle-o-notch fa-spin"></i></span>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <!-- end row -->

        </div> <!-- container -->

    </div> <!-- content -->


