import { Component, Input, OnInit, Output, ViewChild,EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from 'src/app/services/users/user.service';
import { DataUser } from '../models/user';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  
  @ViewChild( "showModale") showModale;
  @ViewChild("closeButton") closeButton;
  errorMessage :string;
  constructor(private http : UserService) { }
  @Output() childEdited: EventEmitter<string> =   new EventEmitter();
  @Input() user : DataUser;
  ngOnInit(): void {
   
  }

  editZoner(body :NgForm ){
    this.errorMessage="";
    this.http.editeUser(body).subscribe(response=>{ 
          if(response.status == 1 ){
            this.closeButton.nativeElement.click();
            this.childEdited.emit('user Edited Successfully');
          }else{
            this.errorMessage ='* '+ response.message;
          }
    })
}

}
