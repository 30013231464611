<span data-toggle="modal" [attr.data-target]="'#Modal'+ productes.id"><i class="fa fa-edit ccc{{productes.id}}"></i></span>
<!--  Modal content for the above example -->
<div class="modal" id="Modal{{productes.id}}" tabindex="-1" role="dialog" aria-labelledby="ModalTitle" aria-hidden="true" #showModale>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" #closeButton>×</button>
                <h4 class="modal-title" id="myLargeModalLabel">Edit User</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="card-box">
                        <form  #data="ngForm" (ngSubmit)='editproductes(data.value)'>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName"> Name<span class="text-danger">*</span></label>
                                        <input type="text" required
                                                class="form-control"  name="name" ngModel [(ngModel)]="productes.name">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Image Link<span class="text-danger">*</span></label>
                                        <input type="text"  required  [(ngModel)]="productes.image"
                                                class="form-control"  name="image_link" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Product Id<span class="text-danger">*</span></label>
                                        <input type="text"  required  [(ngModel)]="productes.product_id"
                                                class="form-control"  name="id" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Category Id<span class="text-danger">*</span></label>
                                        <input type="text"  required  [(ngModel)]="productes.category_id"
                                                class="form-control"  name="cat_id" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Price<span class="text-danger">*</span></label>
                                        <input type="text"  required  [(ngModel)]="productes.price"
                                                class="form-control"  name="price" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Description<span class="text-danger">*</span></label>
                                        <input type="text"  required  [(ngModel)]="productes.desc"
                                                class="form-control"  name="desc" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-6" >
                                    <div class="form-group">
                                        <input type="hidden" required [(ngModel)]="productes.id"
                                                class="form-control"  name="id" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="alert" role="alert" #errorDiv>
                                         {{errorMessage}}
                                      </div>
                                </div>
                            </div>
                            <div class="form-group text-right m-b-0">
                                <button class="btn btn-custom waves-effect waves-light AddUser" type="submit">
                                    Edit
                                </button>
                                <button type="reset" class="btn btn-light waves-effect m-l-5" data-dismiss="modal" aria-hidden="true">
                                    Cancel
                                </button>
                            </div>

                        </form>
                    </div> <!-- end card-box -->
                </div>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->