<span data-toggle="modal" [attr.data-target]="'#Modal'+ statusOrder.id"><button class="buttonStatus" [ngStyle]="{ 'background-color' : getColor(statusOrder.status)}"> {{getStatus(statusOrder.status)}} </button></span>
<!--  Modal content for the above example -->
<div class="modal" id="Modal{{statusOrder.id}}" tabindex="-1" role="dialog" aria-labelledby="ModalTitle" aria-hidden="true" #showModale>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" #closeButton>×</button>
                <h4 class="modal-title" id="myLargeModalLabel">Change Status</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="card-box">
                        <form  #data="ngForm" (ngSubmit)='ChangeStatus(data.value , statusOrder.id)'>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="effect">
                                        <label>Choose Status <span class="text-danger">*</span></label>
                                        <select data-placeholder="chose your job" class="custom-select mt-3" tabindex="-1" name="status" ngModel [ngModel]="statusOrder.status">
                                            <option [ngValue]="null" select >اختر...</option>
                                            <option  *ngFor="let item of allStatus"  [ngValue]="item">{{getStatus(item)}} </option>
                                        </select>
                                    </div>
                                </div>
                                
                                <div class="col-lg-6" >
                                    <div class="form-group">
                                        <input type="hidden" required [(ngModel)]="statusOrder.id"
                                                class="form-control"  name="user_id" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="alert" role="alert" #errorDiv>
                                         {{errorMessage}}
                                      </div>
                                </div>
                            </div>
                            <div class="form-group text-right m-b-0">
                                <button class="btn btn-custom waves-effect waves-light AddUser" type="submit">
                                    Edit
                                </button>
                                <button type="reset" class="btn btn-light waves-effect m-l-5" data-dismiss="modal" aria-hidden="true">
                                    Cancel
                                </button>
                            </div>

                        </form>
                    </div> <!-- end card-box -->
                </div>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->