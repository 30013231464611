    <!-- Start Page content -->
    <div class="content">
        <div class="container-fluid">

            <div class="row">
                <div class="col-12">
                    <div class="card-box">
                        <div class="topHeader">
                            <h3>Uaers 
                                <span class="buttonModale">
                                    <app-add-users (childAdded)="onChildAdded($event)"></app-add-users>
                                </span>
                            </h3>
                        </div>
                        <div class="table-rep-plugin">
                            
                            <div class="table-responsive" data-pattern="priority-columns">
                                <table id="tech-companies-1" class="table  table-striped">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>select</th>
                                        <th> Name</th>
                                        <th>User Name</th>
                                        <th>System Code</th>
                                        <th>Phone</th>
                                        <th>Agent Code</th>
                                        <th>Branch name</th>
                                        <th>Active</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody  *ngIf='!loading'>
                                    <tr *ngFor='let item of useras'>
                                        <td>{{item.id}}</td>
                                        <td><input type="checkbox" #checkboxes type="checkbox" name="chek" data-md-icheck ngModel (ngModelChange)="selectItem($event,item)"></td>
                                        <td>{{item.name}}</td>
                                        <td>{{item.user_name}}</td>
                                        <td>{{item.sys_code}}</td>
                                        <td>{{item.phone}}</td>
                                        <td>{{item.agent_code}}</td>
                                        <td>{{item.branch_id}}</td>
                                        <td><button class="buttonStatus" (click)='clicsweetActive(item.id)' [ngStyle]="{ 'background-color' : getColor(item.status)}"> {{getStatus(item.status)}}  </button></td>
                                        <td>
                                            <ul class="StatusIcon">
                                                <li class="edit"><app-edit-user [user]=item (childEdited)='onChildAdded($event)'></app-edit-user></li>
                                                <li class="delete" (click)='clickSweet(item.id)'><i class="fa fa-trash"></i></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="responsiveLoading" *ngIf='loading'>
                                    <span><i class="fa fa-circle-o-notch fa-spin"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->
        </div> <!-- container -->
    </div> <!-- content -->


