<span data-toggle="modal" [attr.data-target]="'#Modal'+ user.id"><i class="fa fa-edit ccc{{user.id}}"></i></span>
<!--  Modal content for the above example -->
<div class="modal" id="Modal{{user.id}}" tabindex="-1" role="dialog" aria-labelledby="ModalTitle" aria-hidden="true" #showModale>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" #closeButton>×</button>
                <h4 class="modal-title" id="myLargeModalLabel">Edit User</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="card-box">
                        <form  #data="ngForm" (ngSubmit)='editZoner(data.value)'>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName"> Name<span class="text-danger">*</span></label>
                                        <input type="text" required
                                                class="form-control"  name="name" ngModel [(ngModel)]="user.name">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">User Name<span class="text-danger">*</span></label>
                                        <input type="text"  required  [(ngModel)]="user.user_name"
                                                class="form-control"  name="user_name" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">System Name<span class="text-danger">*</span></label>
                                        <input type="text" required
                                                class="form-control"  name="sys_code" ngModel [(ngModel)]="user.sys_code">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName"> Phone<span class="text-danger">*</span></label>
                                        <input type="text" required
                                                class="form-control"  name="phone" ngModel [(ngModel)]="user.phone">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName"> Agent Code<span class="text-danger">*</span></label>
                                        <input type="text" required
                                                class="form-control"  name="agent_code" ngModel [(ngModel)]="user.agent_code">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName"> Branch Name<span class="text-danger">*</span></label>
                                        <input type="text" required
                                                class="form-control"  name="branch_id" ngModel [(ngModel)]="user.branch_id">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">     
                                        <label for="userName">Password<span class="text-danger">*</span></label>
                                        <input type="password"  required  placeholder="*******"
                                                class="form-control"  name="password" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">     
                                        <label for="userName">password confirmation<span class="text-danger">*</span></label>
                                        <input type="password"  required  placeholder="*******"
                                                class="form-control"  name="password_confirmation" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-6" >
                                    <div class="form-group">
                                        <input type="hidden" required [(ngModel)]="user.id"
                                                class="form-control"  name="id" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="alert" role="alert" #errorDiv>
                                         {{errorMessage}}
                                      </div>
                                </div>
                            </div>
                            <div class="form-group text-right m-b-0">
                                <button class="btn btn-custom waves-effect waves-light AddUser" type="submit">
                                    Edit
                                </button>
                                <button type="reset" class="btn btn-light waves-effect m-l-5" data-dismiss="modal" aria-hidden="true">
                                    Cancel
                                </button>
                            </div>

                        </form>
                    </div> <!-- end card-box -->
                </div>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->