import { Component, OnInit, Output, ViewChild,EventEmitter, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApisCategoriesService } from 'src/app/services/category/apis-categories.service';
import { CategoryData } from '../models/product_categories';

@Component({
  selector: 'app-edite-category',
  templateUrl: './edite-category.component.html',
  styleUrls: ['./edite-category.component.scss']
})
export class EditeCategoryComponent implements OnInit {

  
  @ViewChild( "showModale") showModale;
  @ViewChild("closeButton") closeButton;
  errorMessage :string;
  constructor(private httpZone :ApisCategoriesService) { }
  @Output() childEdited: EventEmitter<string> =   new EventEmitter();
  @Input() categories : CategoryData;
  ngOnInit(): void {
   
  }

  editCategories(body :NgForm ){
    this.errorMessage="";
    this.httpZone.editeCategories(body ).subscribe(response=>{ 
          if(response.status == 1 ){
            this.closeButton.nativeElement.click();
            this.childEdited.emit('user Edited Successfully');
          }else{
            this.errorMessage ='* '+ response.message;
          }
    })
}

}
