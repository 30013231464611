<button type="button" class="btn btn-info waves-effect waves-light" data-toggle="modal" data-target="#bs-example-modal-lg">({{productsItems.length}})  Products</button>
<!--  Modal content for the above example -->
<div class="modal" id="bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="ModalTitle" aria-hidden="true" #showModale>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" #closeButton>×</button>
                <h4 class="modal-title" id="myLargeModalLabel">All Products</h4>
            </div>
            <div class="modal-body">
                <div class="row" *ngFor='let item of this.realProducts'>
                    <div class="card-box">
                        <div class="row">
                            <div class="orderImage col-lg-2">
                                <img src="../../../assets/images/photo-icon-sad.jpg">
                            </div>
                            <div class="OrderPrice col-lg-10">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <p> <span>Name Product :</span> name </p>
                                    </div>
                                    <div class="col-lg-6">
                                        <p><span>Price :</span> 1000 $</p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        
                        
                    </div> <!-- end card-box -->
                </div>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->