import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApisHomeServicesService } from 'src/app/services/home/apis-home-services.service';
import { Data } from '../../models/home';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  home :Data;
  loading =true;
  constructor(private http :ApisHomeServicesService, private router :Router) { 
    let token = localStorage.getItem('token');
    if(token == null){
      this.router.navigate(['/login']);
    }
   }
  ngOnInit(): void {
    this.http.getHome().subscribe(response =>{
      this.home = response.data
      this.loading = false
    })
  
  }
  
}
