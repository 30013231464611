import { Component, Input, OnInit, Output, ViewChild ,EventEmitter} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApisProductsService } from 'src/app/services/category/apis-products.service';
import { DataEditeProduct } from '../models/product_categories';

@Component({
  selector: 'app-edite-product',
  templateUrl: './edite-product.component.html',
  styleUrls: ['./edite-product.component.scss']
})
export class EditeProductComponent implements OnInit {

  @ViewChild( "showModale") showModale;
  @ViewChild("closeButton") closeButton;
  errorMessage :string;
  constructor(private httpZone :ApisProductsService) { }
  @Output() childEdited: EventEmitter<string> =   new EventEmitter();
  @Input() productes ;
  ngOnInit(): void {
  }
  editproductes(body :NgForm ){
    this.errorMessage="";
    this.httpZone.editeProducts(body ).subscribe(response=>{ 
          if(response.status == 1 ){
            this.closeButton.nativeElement.click();
            this.childEdited.emit('user Edited Successfully');
          }else{
            this.errorMessage ='* '+ response.message;
          }
    })
}
}
