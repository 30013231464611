import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApisSliderService } from 'src/app/services/slider/apis-slider.service';

@Component({
  selector: 'app-add-slider-model',
  templateUrl: './add-slider-model.component.html',
  styleUrls: ['./add-slider-model.component.scss']
})
export class AddSliderModelComponent implements OnInit {

  loading =false
  @ViewChild( "showModale") showModale;
  @ViewChild("closeButton") closeButton;
  errorMessage :string;
  constructor(private http :ApisSliderService ) { }
  @Output() childAdded: EventEmitter<string> =   new EventEmitter();

  Data :FormData = new FormData();

  ngOnInit(): void {
    
  }
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
        let file: File = fileList[0];
        this.Data.append('image', file, file.name);
    }
  }
  addSlider(form :NgForm){
    this.loading = true
    this.errorMessage =""
      this.http.addSlider(this.Data).subscribe(response=>{ 
        this.loading = false
            if(response.status == 1 ){
              form.resetForm();
              this.closeButton.nativeElement.click();
                this.childAdded.emit('user Added Successfully');
                this.removeFaild()
            }
            else{
              this.errorMessage ='* '+ response.message;
            }
      })
  }
  removeFaild(){

  }

}
