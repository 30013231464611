import { Component, OnInit, ViewChild,EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from 'src/app/services/users/user.service';

@Component({
  selector: 'app-add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.scss']
})
export class AddUsersComponent implements OnInit {

   
  @ViewChild( "showModale") showModale;
  @ViewChild("closeButton") closeButton;
  errorMessage :string;
  constructor(private http :UserService ) { }
  @Output() childAdded: EventEmitter<string> =   new EventEmitter();


  loading = false
  ngOnInit(): void {
    
  }

  addUser(body :NgForm){
    this.loading = true
    this.errorMessage =""
      this.http.addUser(body.value).subscribe(response=>{ 
        this.loading = false
            if(response.status == 1 ){
              body.resetForm();
              this.closeButton.nativeElement.click();
                this.childAdded.emit('user Added Successfully');
                this.removeFaild();

            }
            else{
              this.errorMessage ='* '+ response.message;
            }
      })
  }
  removeFaild(){

  }
}
