
<button type="button" class="btn btn-info waves-effect waves-light btn-notification" data-toggle="modal" data-target="#notification">send Notification</button>
<!--  Modal content for the above example -->
<div class="modal" tabindex="-1" id="notification" tabindex="-1" role="dialog" aria-labelledby="ModalTitle" aria-hidden="true" #sendModale>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" #closeButton>×</button>
                <h4 class="modal-title" id="myLargeModalLabel">Send Notification</h4>
            </div>
            <div class="modal-body">
                    <div class="card-box">
                        <form  #data="ngForm" (ngSubmit)='sendNotification(data.value)'>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="userName">Title<span class="text-danger">*</span></label>
                                        <input type="text" name="nick" parsley-trigger="change" required
                                               placeholder="Enter Title" class="form-control" id="userName" name="title" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="userName">Description<span class="text-danger">*</span></label>
                                        <textarea type="text" name="nick" parsley-trigger="change" required
                                               placeholder="Enter Description" class="form-control" id="userName" name="desc" ngModel></textarea> 
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input type="hidden" name="nick" parsley-trigger="change" required [(ngModel)]="ids"
                                               placeholder="Enter Title" class="form-control" id="userName" name="ids" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="alert" role="alert" #errorDiv>
                                         {{errorMessage}}
                                      </div>
                                </div>
                            </div>
                            <div class="form-group text-right m-b-0">
                                <button class="btn btn-custom waves-effect waves-light AddUser" type="submit">
                                    Send
                                </button>
                                <button type="reset" class="btn btn-light waves-effect m-l-5" data-dismiss="modal" aria-hidden="true">
                                    Cancel
                                </button>
                            </div>

                        </form>
                    </div> <!-- end card-box -->
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->