import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ApisSliderService } from 'src/app/services/slider/apis-slider.service';
import { DataSlider } from '../models/slider';

@Component({
  selector: 'app-edit-slider-modal',
  templateUrl: './edit-slider-modal.component.html',
  styleUrls: ['./edit-slider-modal.component.scss']
})
export class EditSliderModalComponent implements OnInit {

  @ViewChild( "showModale") showModale;
  @ViewChild("closeButton") closeButton;
  errorMessage :string;
  constructor(private http : ApisSliderService) { }
  @Output() childEdited: EventEmitter<string> =   new EventEmitter();
  @Input() slider : DataSlider;
  Data :FormData = new FormData();
  ngOnInit(): void {
   
  }
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
        let file: File = fileList[0];
        this.Data.append('image', file, file.name);
    }
  }
  
  editSlider( id ){
    this.errorMessage="";
    this.http.editeSlider(this.Data , id).subscribe(response=>{ 
          if(response.status == 1 ){
            this.closeButton.nativeElement.click();
            this.childEdited.emit('user Edited Successfully');
          }else{
            this.errorMessage ='* '+ response.message;
          }
    })
}


}
