import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApisAuthService } from 'src/app/services/apis-auth.service';
import { ApisZonesService } from 'src/app/services/zones/apis-zones.service';
import { DataZones } from '../models/zones';

@Component({
  selector: 'app-edit-zone-modal',
  templateUrl: './edit-zone-modal.component.html',
  styleUrls: ['./edit-zone-modal.component.scss']
})
export class EditZoneModalComponent implements OnInit {

 
  @ViewChild( "showModale") showModale;
  @ViewChild("closeButton") closeButton;
  errorMessage :string;
  constructor(private http : ApisAuthService ,private httpZone :ApisZonesService) { }
  @Output() childEdited: EventEmitter<string> =   new EventEmitter();
  @Input() zone : DataZones;
  ngOnInit(): void {
   
  }

  editZoner(body :NgForm ,id ){
    this.errorMessage="";
    this.httpZone.editeZones(body , id).subscribe(response=>{ 
          if(response.status == 1 ){
            this.closeButton.nativeElement.click();
            this.childEdited.emit('user Edited Successfully');
          }else{
            this.errorMessage ='* '+ response.message;
          }
    })
}
}
