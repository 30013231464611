import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/users/user.service';
import Swal from 'sweetalert2';
import { DataUser } from '../../models/user';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  useras : DataUser[];
  ids : number[] =[]; 
  loading =true;
  formBody : FormData = new FormData;
  constructor(private http :UserService ,private router: Router) {
    let token = localStorage.getItem('token');
    if(token == null){
      this.router.navigate(['/login']);
    }
   }
  ngOnInit(): void {
    this.http.getUser().subscribe(response =>{
      this.useras = response.data
      this.loading = false
    })
  }
  getColor(status :number){
   
    switch(status){
            case 0 :
                return "#f52846";
            case 1 :
                return "#31d43d";
            default:
                return "#31d43d";
        }
  }

  getStatus(status :number){
    switch(status){
      case 0 :
          return " Block";
      case 1 :
          return "Active ";
      default:
          return " Active";
    }

  }
  selectItem(e, item){
    if(e)
    this.ids.push(item.id);
    else{
      let index = this.ids.indexOf(item.id)
      this.ids.splice(index , 1);
    }
    console.log(this.ids)
  }

  clicsweetActive(id ){
    this.formBody.append('id' ,id),
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, change it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.http.ChangeStatus(this.formBody).subscribe(response =>{
          if(response.status == 1){
            this.http.getUser().subscribe(response =>{
              this.useras = response.data;
            })
            Swal.fire(
              'change Status!',
              'success'
            )
          }
        })
        
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }
  clickSweet(id){
    this.formBody.append('id' ,id),
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.http.deleteUser(this.formBody).subscribe(response =>{
          if(response.status == 1){
            this.http.getUser().subscribe(response =>{
              this.useras = response.data;
            })
            Swal.fire(
              'Deleted!',
              'Your imaginary file has been deleted.',
              'success'
            )
          }
        })
        
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }
  onChildAdded(e){
    Swal.fire(
      'Success',
      e,
      'success'
    )
    this.http.getUser().subscribe(response =>{
      this.useras = response.data;
    })
  }

}
