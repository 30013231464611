<span data-toggle="modal" [attr.data-target]="'#Modal'+ zone.id"><i class="fa fa-edit ccc{{zone.id}}"></i></span>
<!--  Modal content for the above example -->
<div class="modal" id="Modal{{zone.id}}" tabindex="-1" role="dialog" aria-labelledby="ModalTitle" aria-hidden="true" #showModale>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" #closeButton>×</button>
                <h4 class="modal-title" id="myLargeModalLabel">Edit User</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="card-box">
                        <form  #data="ngForm" (ngSubmit)='editZoner(data.value , zone.id)'>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Zone Name<span class="text-danger">*</span></label>
                                        <input type="text" required
                                                class="form-control"  name="zone_name" ngModel [(ngModel)]="zone.zone_name">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Zone Code<span class="text-danger">*</span></label>
                                        <input type="text"  required  [(ngModel)]="zone.zone_code"
                                                class="form-control"  name="zone_code" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">     
                                        <label for="userName">Shipping Price<span class="text-danger">*</span></label>
                                        <input type="text"  required  [(ngModel)]="zone.shipping_price"
                                                class="form-control"  name="shipping_price" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">     
                                        <label for="userName">Printer Code<span class="text-danger">*</span></label>
                                        <input type="text"  required  [(ngModel)]="zone.printer_code"
                                                class="form-control"  name="printer_code" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>from</label>
                                        <div class="input-group clockpicker" data-placement="top" data-align="top" data-autoclose="true">
                                            <input type="time" required  [(ngModel)]="zone.from"
                                            class="form-control"  name="from" ngModel value="zone.from">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>To</label>
                                        <div class="input-group clockpicker" data-placement="top" data-align="top" data-autoclose="true">
                                            <input type="time" required  [(ngModel)]="zone.to"
                                            class="form-control"  name="to" ngModel value="zone.to">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6" >
                                    <div class="form-group">
                                        <input type="hidden" required [(ngModel)]="zone.id"
                                                class="form-control"  name="id" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="alert" role="alert" #errorDiv>
                                         {{errorMessage}}
                                      </div>
                                </div>
                            </div>
                            <div class="form-group text-right m-b-0">
                                <button class="btn btn-custom waves-effect waves-light AddUser" type="submit">
                                    Edit
                                </button>
                                <button type="reset" class="btn btn-light waves-effect m-l-5" data-dismiss="modal" aria-hidden="true">
                                    Cancel
                                </button>
                            </div>

                        </form>
                    </div> <!-- end card-box -->
                </div>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->