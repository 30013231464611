import { Component, OnInit,EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApisCategoriesService } from 'src/app/services/category/apis-categories.service';
import { CategoryData, Dish } from '../models/product_categories';

@Component({
  selector: 'app-single-product-category',
  templateUrl: './single-product-category.component.html',
  styleUrls: ['./single-product-category.component.scss']
})
export class SingleProductCategoryComponent implements OnInit {

  loading = true;
  @Output() childShow: EventEmitter<string> =   new EventEmitter();
  @Input() productsItems : Dish[];
  @Input() category : CategoryData;
   realProducts : Dish[] ; 
  constructor(private http :ApisCategoriesService, private router :Router) { 
    let token = localStorage.getItem('token');
    if(token == null){
      this.router.navigate(['/login']);
    }
   }
  ngOnInit(): void {
    console.log("products of category  :"+ this.productsItems.length);
    console.log("category :"+ this.category +"\n");
    this.realProducts = this.productsItems ; 
    console.log("real Products  of category  :"+ this.realProducts.length);


  }


}
