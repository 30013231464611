import { Component, Input, OnInit, Output, ViewChild,EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApisBranchServicesService } from 'src/app/services/home/apis-branch-services.service';
import { DataBranch } from '../models/branch';

@Component({
  selector: 'app-edite-branch',
  templateUrl: './edite-branch.component.html',
  styleUrls: ['./edite-branch.component.scss']
})
export class EditeBranchComponent implements OnInit {

  @ViewChild( "showModale") showModale;
  @ViewChild("closeButton") closeButton;
  errorMessage :string;
  constructor(private httpZone :ApisBranchServicesService) { }
  @Output() childEdited: EventEmitter<string> =   new EventEmitter();
  @Input() branch : DataBranch;
  ngOnInit(): void {
   
  }

  editBranch(body :NgForm ,id ){
    this.errorMessage="";
    this.httpZone.editeBranch(body , id).subscribe(response=>{ 
          if(response.status == 1 ){
            this.closeButton.nativeElement.click();
            this.childEdited.emit('user Edited Successfully');
          }else{
            this.errorMessage ='* '+ response.message;
          }
    })
}
}
