import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UsersResponce } from 'src/app/components/models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  BaseUrl ='http://api.golden.spedo.apishipping.iraq-soft.info/'
  token :string;
  lang :string;
  constructor(private http: HttpClient) { }

  public getUser():  Observable<UsersResponce>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<UsersResponce>(this.BaseUrl+ 'admin/user/get' ,{headers});
  }

  public deleteUser(body):  Observable<UsersResponce>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<UsersResponce>(this.BaseUrl+ 'admin/user/delete' ,body,{headers});
  }

  public editeUser(body ):  Observable<UsersResponce>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<UsersResponce>(this.BaseUrl+ 'admin/user/edit' ,body,{headers});
  }
  public addUser(body):  Observable<UsersResponce>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<UsersResponce>(this.BaseUrl+ 'admin/user/register' ,body,{headers});
  }

  public ChangeStatus(body):  Observable<UsersResponce>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<UsersResponce>(this.BaseUrl+ 'admin/user/toggle-block' ,body,{headers});
  }
}
