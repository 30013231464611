import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ApisCategoriesService } from 'src/app/services/category/apis-categories.service';
import Swal from 'sweetalert2';
import { CategoryData } from '../../models/product_categories';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

 
  categories :CategoryData[];
  ids : number[] =[];  
  loading =true;
  formBody : FormData = new FormData;
  constructor(private http :ApisCategoriesService, private router :Router) { 
    let token = localStorage.getItem('token');
    if(token == null){
      this.router.navigate(['/login']);
    }
    this.http.getAllCategories().subscribe(response =>{
      this.categories = response.data
      console.log(this.categories[1].dishes);
      this.loading = false;
    })
   }
  ngOnInit(): void {

  
  }
  onChildShow(e){
    Swal.fire(
      'Success',
      e,
      'success'
    )
    this.http.getAllCategories().subscribe(response =>{
      this.categories = response.data;
    })
  }
  clickSweet(id){
   this.formBody.append('id' ,id),
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.http.deleteCategories(this.formBody).subscribe(response =>{
          if(response.status == 1){
            this.http.getAllCategories().subscribe(response =>{
              this.categories = response.data;
            })
            Swal.fire(
              'Deleted!',
              'Your imaginary file has been deleted.',
              'success'
            )
          }
        })
        
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }

  onChildAdded(e){
    Swal.fire(
      'Success',
      e,
      'success'
    )
    this.http.getAllCategories().subscribe(response =>{
      this.categories = response.data;
    })
  }
}
