    <!-- Start Page content -->
    <div class="content">
        <div class="container-fluid">

            <div class="row">
                <div class="col-12">
                    <div class="card-box">
                        <div class="topHeader">
                            <h3>Orders
                                
                                <span class="buttonModale">
                                    <app-notification [ids]='ids' (childNotification)="onNotificationSend($event)"></app-notification>
                                </span>
                            </h3>
                        </div>
                        <div class="table-rep-plugin">
                            <div class="table-responsive" data-pattern="priority-columns">
                                <table id="tech-companies-1" class="table  table-striped">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>select</th>
                                        <th>User Name </th>
                                        <th>Phone</th>
                                        <th>Zone Name</th>
                                        <th>Branch Name</th>
                                        <th>Status</th>
                                        <th>Type Order</th>
                                        <th>Shipping Price</th>
                                        <th>Total Price </th>
                                        <th>Order Products Count</th>
                                    </tr>
                                    </thead>
                                    <tbody  *ngIf='!loading'>
                                    <tr *ngFor='let item of orders'>
                                        <th>{{item.id}}</th>
                                        <th><input type="checkbox" #checkboxes type="checkbox" name="chek" data-md-icheck ngModel (ngModelChange)="selectItem($event,item)"></th>
                                        <th>{{item.name}}</th>
                                        <th> {{item?.phone}} </th>
                                        <th>{{item?.zone?.zone_name}}</th>
                                        <th>{{item?.branch?.name}}</th>
                                        <th><app-status-modal [statusOrder]=item (childEdited)='onChildStauts($event)'></app-status-modal></th>
                                        <th><button class="buttonStatus" [ngStyle]="{ 'color' : getColor(item?.order_type)}"> {{getType(item?.order_type)}} </button></th>
                                        <th> {{item?.shipping_price}} </th>
                                        <th> {{item?.total_price}} </th>
                                        <th>
                                            <app-single-oreder-modal [produst]=item.products  [order]=item (childShow)="onChildShow($event)"></app-single-oreder-modal>
                                        </th>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="responsiveLoading" *ngIf='loading'>
                                    <span><i class="fa fa-circle-o-notch fa-spin"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->
        </div> <!-- container -->
    </div> <!-- content -->


