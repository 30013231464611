import { Component, OnInit, Output, ViewChild,EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApisProductsService } from 'src/app/services/category/apis-products.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  
  @ViewChild( "showModale") showModale;
  @ViewChild("closeButton") closeButton;
  errorMessage :string;
  constructor(  private httpZone :ApisProductsService) { }
  @Output() childAdded: EventEmitter<string> =   new EventEmitter();


  loading = false
  ngOnInit(): void {
    
  }

  addProduct(body :NgForm){
    this.loading = true
    this.errorMessage =""
      this.httpZone.addProducts(body.value).subscribe(response=>{ 
        this.loading = false
            if(response.status == 1 ){
              body.resetForm();
              this.closeButton.nativeElement.click();
                this.childAdded.emit('user Added Successfully');
                this.removeFaild();

            }
            else{
              this.errorMessage ='* '+ response.message;
            }
      })
  }
  removeFaild(){

  }

}
